import { Link } from 'gatsby'
import React, { useState, Fragment } from 'react'
import { fontRegular, theme } from '../util/style'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import LogoMark from './svg/happy-haus-wordmark'
import HeaderMobile from './header-mobile'
import useWindowSize from '@rehooks/window-size'

import { useLocation } from '@reach/router'
import { useEffect } from 'react'

import { landingPages } from '../util/site-config'
import HeaderLogoSecondary from './svg/header-logo-secondary'

const Header = ({ landing }) => {
  const [dropVisible, setDropVisible] = useState(false)
  const [logoState, setLogoState] = useState(0)

  const location = useLocation()

  useEffect(() => {
    const landingPagesSlugs = landingPages?.map(({ slug }) => '/' + slug)
    const { pathname } = location
    if (pathname === '/' || landingPagesSlugs.includes(pathname)) {
      setLogoState(0)
    } else {
      setLogoState(1)
    }
  }, [location])

  const windowSize =
    typeof window !== `undefined`
      ? useWindowSize()
      : { innerHeight: null, innerWidth: null }
  const { innerHeight, innerWidth } = windowSize

  const handleEnter = () => {
    setDropVisible(true)
  }

  const handleLeave = () => {
    setDropVisible(false)
  }

  //const renderNav = !(uri === '/draft-display-haus' || uri === '/display-haus')

  if (innerHeight && innerWidth) {
    if (innerWidth < 950) {
      return <HeaderMobile logoState={logoState} />
    } else {
      const dropMenu = (
        <Drop
          isVisible={dropVisible}
          onMouseOver={handleEnter}
          onMouseOut={handleLeave}
        >
          <li>
            <Link to="/custom-haus">Custom Haus</Link>
          </li>
          <li>
            <Link to="/haus-series">Haus Series</Link>
          </li>

          <li>
            <Link to="/specifications">Specifications</Link>
          </li>
        </Drop>
      )

      const navItems = (
        <NavDesktopItemsWrapper pb={'5px'}>
          <ul id="nav-desktop">
            <li>
              <Link
                to="/haus-series"
                onMouseOver={handleEnter}
                onMouseOut={handleLeave}
              >
                Design <Caret />
              </Link>
              {dropMenu}
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <Link to="/articles">Articles</Link>
            </li>
            <li>
              <Link to="/resources">Resources</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/philosophy">Philosophy</Link>
            </li>
            <li style={{ paddingRight: '0' }}>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </NavDesktopItemsWrapper>
      )

      return (
        <Fragment>
          <Wrap>
            <Flex>
              <Box width={1} px={2}>
                <WrapInner>
                  <Title>
                    <Link to="/">
                      <LogoMark isMobile={false} />
                    </Link>
                  </Title>
                  <Nav>{landing ? <NavLanding /> : navItems}</Nav>
                </WrapInner>
              </Box>
            </Flex>
          </Wrap>
        </Fragment>
      )
    }
  } else {
    return null
  }
}

const NavLanding = () => {
  return (
    <Tel>
      <a href="tel:+61731865771">Call (07) 3186 5771</a>
    </Tel>
  )
}

export default Header

const Wrap = styled.header`
  max-width: ${theme.dimensions.maxWidth};
  margin: 0 auto;
  color: ${theme.colors.grey1};
  text-align: center;
  z-index: 1;
  position: relative;
  a {
    color: ${theme.colors.grey1};
    display: inline-block;
  }
`

const WrapInner = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 65px;
  padding-bottom: 62px;
  position: relative;
`

const Caret = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: ${theme.colors.grey1} transparent transparent transparent;
  display: inline-block;
  position: relative;
  top: -2px;
  margin-left: 4px;
`

const Nav = styled.nav`
  margin-left: auto;
  width: 50%;

  font-family: ${theme.font.fontFamilyRegular};

  @media (max-width: 1500px) {
    width: 55%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      display: inline-block;
      /* margin: 0 0 0 32px; */
      position: relative;
      font-size: 20px;
      padding-right: 22px;
      letter-spacing: ${theme.letterSpacing(-1)};

      @media (max-width: 1295px) {
        padding-right: 12px;
      }
    }
  }

  #nav-desktop {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
  }

  @media (max-width: 800px) {
    ul {
      li {
        /* margin-left:  */
      }
    }
  }
`

const Drop = styled.ul`
  position: absolute;
  z-index: 1;
  left: -20px;
  top: 25px;
  width: 200px;
  text-align: left;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  background: ${theme.colors.white};
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  li {
    margin: 0 !important;
    display: block;
  }
  a {
    color: ${theme.colors.grey1};
    display: block;
    padding: 5px 20px;
    &:hover {
      color: ${theme.colors.grey2};
    }
  }
`

const Title = styled.h1`
  margin: 0;
  position: relative;
  top: 5px;
  a {
    display: inline-block;
    position: relative;
  }
`

const Tel = styled.div`
  font-size: 120%;
  text-align: right;
`

const Cta = styled.div`
  position: absolute;
  right: 0;
  top: 2em;
  margin-bottom: 1em;
  font-size: 19px;
`

const NavDesktopItemsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1295px) {
    justify-content: flex-end;
  }
`
