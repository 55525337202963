import React from 'react'
import styled from 'styled-components'
import { theme, responsiveFont, fontRegular } from '../../util/style'

const Wrap = ({ children }) => {
  return (
    <El>
      <Inner>{children}</Inner>
    </El>
  )
}

export default Wrap

const El = styled.div`
  ${fontRegular()};
  ${responsiveFont()};
  padding: 0 5vw;
  color: ${theme.colors.grey1};
  a {
    text-decoration: none;
  }
  p {
    margin-top: 0px;
  }
  @media (max-width: 1100px) {
    padding: 0 3vw;
  }
  @media (max-width: 800px) {
    padding: 0 10px;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4em 1.6em;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`
