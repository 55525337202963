import { Box } from '@rebass/grid'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../util/style'

const FormTooltip = ({ children, tooltipCopy }) => {
  return (
    <>
      <Wrapper>
        {children}
        <Tooltip>{tooltipCopy}</Tooltip>
      </Wrapper>
    </>
  )
}

export default FormTooltip

const Wrapper = styled(Box)`
  display: inline;
  text-decoration: underline;
  text-decoration-color: ${theme.colors.grey11};
  cursor: pointer;
  position: relative;

  &:hover {
    & > div {
      display: block;
    }
  }
`

const Tooltip = styled(Box)`
  background-color: ${theme.colors.yellow1};
  color: ${theme.colors.red1};
  position: absolute;
  top: -100%;
  left: 100%;
  z-index: 100;
  width: 300px;
  border-radius: 10px;

  display: none;

  padding: 16px;
  font-size: 15px;
  line-height: 140%;

  font-family: ${theme.font.fontFamilySerifThin};

  i {
    font-style: normal;
    font-family: ${theme.font.fontFamilySerifItalic};
  }

  cursor: text;

  a {
    color: ${theme.colors.red1};
    text-decoration: underline !important;
  }
`
