import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme, fontBold } from '../util/style'

const PageCopy = ({ align, children, size, grey, ...rest }) => (
  <Wrap align={align} size={size} grey={grey} {...rest}>
    {children}
  </Wrap>
)

PageCopy.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
}

export default PageCopy

const Wrap = styled.div`
  color: ${theme.colors.grey1};
  font-size: ${(props) => (props.size === 'sm' ? `80%` : `inherit`)};
  line-height: ${(props) => (props.size === 'sm' ? `150%` : `inherit`)};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  padding: ${(props) =>
    !props.align || props.align === 'left' ? '0 0 0 0' : '0 10%'};
  a {
    color: ${theme.colors.grey1};
    border-bottom: 1px solid ${theme.colors.grey11};
    &:hover {
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
  b,
  strong {
    ${fontBold()};
  }
  ${({ grey }) => (grey ? `color: #9F9F9F;` : ``)};
`
