/*eslint no-undef: "off"*/

module.exports = {
  personas: {
    custom: 'persona_4',
    knockdown: 'persona_3',
    haus_and_land: 'persona_5',
    learning: 'persona_6',
    new_haus: 'persona_1',
  },
  landingPages: [
    {
      slug: 'open-haus-tarragindi',
      noPhone: true,
    },
    {
      slug: 'open-haus-tarragindi-invite',
      noPhone: true,
    },
    {
      slug: 'display-haus',
      ctaTitle: 'Stay Inspired, keep dreaming',
      ctaButtonLabel: 'Talk to us',
      formTitle: 'Stay Inspired, keep dreaming',
      formRedirect: '/contact-thank-you',
    },
    {
      slug: 'guide-subtropical-homes',
      ctaTitle: 'Ready to discuss next steps?',
      ctaButtonLabel: 'Talk to us',
      formTitle: 'Ready to discuss next steps?',
      formRedirect: '/guide-subtropical-homes-thank-you',
    },

    // {
    //   slug: 'open-haus-dewar-terrace',
    //   ctaTitle: 'Ready to discuss next steps?',
    //   ctaButtonLabel: 'Talk to us',
    //   formTitle: 'Ready to discuss next steps?',
    //   formRedirect: '/contact-thank-you',
    // },
    {
      slug: 'together',
      ctaTitle: 'Ready to discuss next steps?',
      ctaButtonLabel: 'Contact our team',
      formTitle: 'Ready to discuss next steps?',
      formRedirect: '/contact-thank-you',
    },
    {
      slug: 'guide-knockdown-rebuild',
      ctaTitle: "Let's talk",
      ctaButtonLabel: 'Contact our team',
      formTitle: "Let's talk",
      formRedirect: '/contact-thank-you',
    },
    {
      slug: 'together',
      ctaTitle: 'Stay Inspired, keep dreaming',
      ctaButtonLabel: 'Talk to us',
      formTitle: 'Stay Inspired, keep dreaming',
      formRedirect: '/contact-thank-you',
    },
    {
      slug: 'guide-custom-haus',
      ctaTitle: 'Ready to discuss next steps?',
      ctaButtonLabel: 'Contact our team',
      formTitle: "Let's talk",
      formRedirect: '/contact-thank-you',
    },
    {
      slug: 'builder-checklist',
      ctaTitle: 'Ready to discuss next steps?',
      ctaButtonLabel: 'Contact our team',
      formTitle: 'Request a walk-through',
      formRedirect: '/contact-thank-you',
    },
  ],
}
