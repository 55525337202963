import { Box } from '@rebass/grid'
import React, { Component } from 'react'
import styled from 'styled-components'

export default class LogoMark extends Component {
  constructor(props) {
    super(props)
    const fill = this.props.inverted ? '#ffffff' : '#31333A'
    this.state = {
      currMark: 0,
      marks: [
        <path
          d="M156 35.7921V42.7985H176V35.7921L166 31.6499L156 35.7921Z"
          fill={fill}
        />,
        <path
          d="M155.993 35.7914V42.8002H176V30.4302L155.993 35.7914Z"
          fill={fill}
        />,
        <path d="M176 31.6401H156V42.7887H176V31.6401Z" fill={fill} />,
        <path d="M176 35.8947V42.7901H156V30.6201L176 35.8947Z" fill={fill} />,
      ],
    }
    this.timer = 0
    this._toggleMark = this._toggleMark.bind(this)
  }

  componentDidMount() {
    const self = this
    this.timer = setInterval(() => {
      self._toggleMark()
    }, 500)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  _toggleMark() {
    const { currMark, marks } = this.state
    const nextMark = currMark + 1 === marks.length ? 0 : currMark + 1
    this.setState({
      currMark: nextMark,
    })
  }

  render() {
    const fill = this.props.inverted ? '#ffffff' : '#31333A'
    const width = this.props.isMobile ? '200' : '240'
    const { currMark, marks } = this.state
    const markBlock = marks[currMark]

    return (
      <LogoWrapper>
        <svg
          width={width}
          height="56"
          viewBox="0 0 307 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.54611 42.9299H0.832886V0.814941H8.54611V18.7581H25.3305V0.814941H33.0437V42.9299H25.3305V24.878H8.54611V42.9299Z"
            fill={fill}
          />
          <path
            d="M189.553 42.9299H181.84V0.814941H189.553V18.7581H206.338V0.814941H214.051V42.9299H206.338V24.878H189.553V42.9299Z"
            fill={fill}
          />
          <path
            d="M38.2223 22.0718C38.4395 16.4589 42.8936 12.9282 50.3353 12.9282C59.0262 12.9282 62.394 16.5132 62.394 24.317V35.1083C62.394 36.9732 62.9372 37.6974 64.8745 37.6974V43.0388H62.7742C58.4287 43.0388 56.5457 41.9343 55.7852 38.5846H55.6223C53.9203 41.6084 50.8422 43.3103 46.5873 43.3103C40.5942 43.3103 36.9005 40.1237 36.9005 34.728C36.9005 31.3784 38.3309 28.8978 41.3003 27.3588C44.3784 25.7655 48.8325 25.3853 55.1696 25.0956V23.6109C55.1696 19.6456 53.6306 17.835 50.2629 17.835C46.8589 17.835 45.4104 19.7542 45.3018 22.0718H38.2223V22.0718ZM55.2239 30.2196V29.3867C50.0456 29.6583 47.2934 30.111 45.7544 31.1973C44.7043 31.9216 44.2697 33.0079 44.2697 34.384C44.2697 36.8102 45.8631 38.3493 48.5609 38.3493C51.82 38.3493 55.2239 35.9411 55.2239 30.2196Z"
            fill={fill}
          />
          <path
            d="M68.5502 13.58H75.7021V18.4325H75.8107C77.2954 15.626 80.4278 12.9282 85.063 12.9282C91.6717 12.9282 96.8501 17.1107 96.8501 28.065C96.8501 38.132 91.726 43.6363 84.4655 43.6363C80.2286 43.6363 77.2592 41.4273 76.1004 39.0735H75.9918V53.2687H68.5683V13.58H68.5502ZM82.6911 38.476C86.9822 38.476 89.3541 34.891 89.3541 28.1193C89.3541 21.8908 87.4892 18.2152 82.6368 18.2152C78.5629 18.2152 75.8107 21.6192 75.8107 26.5259V29.6583C75.8107 34.6737 78.1283 38.476 82.6911 38.476Z"
            fill={fill}
          />
          <path
            d="M101.232 13.58H108.384V18.4325H108.492C109.977 15.626 113.109 12.9282 117.745 12.9282C124.353 12.9282 129.532 17.1107 129.532 28.065C129.532 38.132 124.408 43.6363 117.147 43.6363C112.91 43.6363 109.941 41.4273 108.782 39.0735H108.673V53.2687H101.25V13.58H101.232ZM115.391 38.476C119.682 38.476 122.054 34.891 122.054 28.1193C122.054 21.8908 120.189 18.2152 115.336 18.2152C111.263 18.2152 108.51 21.6192 108.51 26.5259V29.6583C108.51 34.6737 110.81 38.476 115.391 38.476Z"
            fill={fill}
          />
          <path
            d="M130.527 13.5796H138.06L143.347 30.0381C144.342 33.0618 144.723 35.3794 144.723 35.3794H144.831C144.831 35.3794 145.266 33.0618 146.207 30.0381L151.277 13.5796H158.538L148.072 44.179C145.755 50.9507 143.22 53.8658 136.014 53.8658C133.805 53.8658 132.537 53.5942 132.157 53.4855V47.927C132.809 48.0899 133.968 48.1986 135.018 48.1986C138.422 48.1986 139.635 47.0398 140.794 44.016L141.174 43.0202L130.527 13.5796Z"
            fill={fill}
          />
          <path
            d="M219.827 22.0718C220.044 16.4589 224.498 12.9282 231.94 12.9282C240.631 12.9282 243.999 16.5132 243.999 24.317V35.1083C243.999 36.9732 244.542 37.6974 246.479 37.6974V43.0388H244.379C240.033 43.0388 238.15 41.9343 237.39 38.5846H237.227C235.525 41.6084 232.447 43.3103 228.192 43.3103C222.199 43.3103 218.505 40.1237 218.505 34.728C218.505 31.3784 219.936 28.8978 222.905 27.3588C225.983 25.7655 230.437 25.3853 236.774 25.0956V23.6109C236.774 19.6456 235.235 17.835 231.868 17.835C228.464 17.835 227.015 19.7542 226.906 22.0718H219.827V22.0718ZM236.829 30.2196V29.3867C231.65 29.6583 228.898 30.111 227.359 31.1973C226.309 31.9216 225.874 33.0079 225.874 34.384C225.874 36.8102 227.468 38.3493 230.166 38.3493C233.425 38.3493 236.829 35.9411 236.829 30.2196Z"
            fill={fill}
          />
          <path
            d="M257.307 31.0339C257.307 33.1342 257.415 35.0535 258.303 36.2666C259.135 37.4797 260.512 38.0229 262.268 38.0229C266.668 38.0229 268.822 35.0535 268.822 29.6035V13.5796H276.191V42.9116H269.094V38.0591H268.985C267.555 41.0285 264.531 43.5091 259.787 43.5091C253.233 43.5091 249.992 40.3224 249.992 33.442V13.5796H257.307V31.0339V31.0339Z"
            fill={fill}
          />
          <path
            d="M297.321 21.4559C297.158 19.3556 295.511 17.8166 292.541 17.8166C289.626 17.8166 287.924 19.3556 287.924 21.4559C287.924 23.3752 289.029 24.371 291.672 24.7512L296.452 25.4031C302.119 26.1816 304.872 28.7527 304.872 33.9854C304.872 40.3225 299.747 43.6722 292.596 43.6722C284.882 43.6722 280.591 40.3769 280.211 34.4743H287.091C287.309 36.5746 288.576 38.7111 292.65 38.7111C295.891 38.7111 297.991 37.2807 297.991 34.8545C297.991 32.8809 297.213 31.7764 294.08 31.3781L289.463 30.7806C284.068 30.0564 281.044 27.4853 281.044 22.2526C281.044 17.1286 284.955 12.8374 292.65 12.8374C299.965 12.8374 303.604 16.2414 304.039 21.4197H297.321V21.4559Z"
            fill={fill}
          />
          {markBlock}
        </svg>
      </LogoWrapper>
    )
  }
}

const LogoWrapper = styled(Box)`
  @media (max-width: 950px) {
    svg {
      width: 180px;
    }
  }
`
