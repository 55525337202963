import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { theme } from '../util/style'
import PageTitle from './page-title'
import Img from 'gatsby-image'
import axios from 'axios'

export default () => {
  const [instagramData, setInstagramData] = useState([])

  useEffect(() => {
    const getInstagramData = async () => {
      try {
      const access_token =
        'IGQVJYalhueHFtdlVTMUdCZA05UNm4zc0NYQlNlX0FwZAGlQUGplaTJXb1pPZAmM3azdYeHVaWXdPOElzYUxkeFEzWno5Q2dYRV9sNlplOXp1UUtKOEJIaEhLamdtSEgxNkk1MC0tejNMWGZAzTkxnbkUzaAZDZD'
      const limit = 4
      const getData = await axios.get(
        `https://graph.instagram.com/me/media?fields=media_url,thumbnail_url,caption,media_type,like_count,shortcode,timestamp,comments_count,username,children{media_url},permalink&limit=${limit}&access_token=${access_token}`
      )

      const {
        data: { data },
      } = getData
      console.log(data)
      setInstagramData(data)
    }
    catch (error) {
      console.log(error)
    }
    }

    getInstagramData()
  }, [])

  return (
    <Wrap>
      <Flex>
        <Box width={[1 / 2, 1]} px={2}>
          <PageTitle>
            Follow us on&nbsp;
            <a href="https://www.instagram.com/happy_haus/" target="_blank">
              <u>Instagram</u>
            </a>
          </PageTitle>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        {instagramData.map(
          ({ id, media_url, thumbnail_url, media_type, permalink }) => {
            console.log(id, media_url, thumbnail_url, media_type, permalink)
            return (
              <Box key={id} width={[1 / 2, 1 / 4, 1 / 4, 1 / 4]} px={2}>
                <ImageWrap>
                  <a href={permalink} target="_blank">
                    <img
                      src={media_type === 'VIDEO' ? thumbnail_url : media_url}
                      alt=""
                    />
                  </a>
                </ImageWrap>
              </Box>
            )
          }
        )}
      </Flex>
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-top: 85px;
  margin-bottom: 85px;
  @media (max-width: 800px) {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
  a {
    color: ${theme.colors.grey1};
  }
`
const ImageWrap = styled.div`
  aspect-ratio: 1 / 1;
  width: clamp(50px, 100%, 500px);
  width a {
    width: 100%;
    display: block;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  @media (max-width: 800px) {
    margin-bottom: 5vw;
  }
`
