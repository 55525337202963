import React from "react";

const FormCheck = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.05469 15.2939L15.6055 1.57617L14.1445 0.673828L6.88281 12.3076L1.87695 5.91602L0.523438 6.97949L7.05469 15.2939Z"
        fill="#585858"
      />
    </svg>
  );
};

export default FormCheck;
