//import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme, fontBold } from '../util/style'
import * as yup from 'yup'
import _ from 'lodash'
import { Pulse } from 'styled-spinkit'
import Title from './page-title'
import JSCookie from 'js-cookie'
import PageCopy from './page-copy'

class ForSaleSubscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      errors: {},
      isSending: false,
      isSent: false,
      pageTitle: '',
    }
    this._handleSubmit = this._handleSubmit.bind(this)
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  _handleSubmit(e) {
    e.preventDefault()
    const self = this
    const { email } = this.state

    this.setState({
      isSending: true,
    })

    const schema = yup.object().shape({
      email: yup.string().required('Message...').email('Must be a valid email'),
    })

    const validationOptions = {
      abortEarly: false,
    }

    const date = new Date()
    const timestamp = date.getTime()
    let pageUri = 'https://happyhaus.com.au'
    let pageTitle = 'Happy Haus'
    let pageCookie = ''
    if (typeof window !== `undefined`) {
      pageUri = window.location.href
      pageTitle = document.title
      pageCookie = JSCookie.get('hubspotutk')
    }
    const context = {
      hutk: pageCookie,
      pageUri: pageUri,
      pageName: pageTitle,
    }

    const data = {
      submittedAt: timestamp,
      email: email,
      context: context,
    }

    schema
      .validate(data, validationOptions)
      .then(function (value) {
        fetch('/.netlify/functions/subscribe-hubspot', {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then(function (response) {
            if (response.status === 200) {
              self.setState({
                isSending: false,
                isSent: true,
              })
            }
          })
          .catch(function (err) {
            self.setState({ ...self.state, isSending: false })
          })
      })
      .catch(function (err) {
        const newErrors = {}
        _.forEach(err.inner, function (err) {
          newErrors[err.path] = err.message
        })
        self.setState({ ...self.state, errors: newErrors, isSending: false })
      })
  }

  _handleChange(e) {
    const val = e.target.value
    this.setState({
      email: val,
    })
  }

  _isError(field) {
    return this.state.errors[field]
  }

  render() {
    const { isSending, isSent } = this.state

    let submitButton
    if (isSending) {
      submitButton = (
        <PulseWrap>
          <Pulse size="32" color={theme.colors.bronze} />
        </PulseWrap>
      )
    } else {
      submitButton = (
        <EmailButton onClick={this._handleSubmit}>Subscribe</EmailButton>
      )
    }

    let formContent
    if (isSent) {
      formContent = (
        <Flex>
          <Box width={1}>
            <Fields>
              <Msg>
                You’ve got mail. Be sure to check your inbox and open our
                confirmation email.
              </Msg>
            </Fields>
          </Box>
        </Flex>
      )
    } else {
      formContent = (
        <Flex>
          <Box width={1}>
            <Fields isSending={isSending}>
              <EmailInput
                type="text"
                placeholder="Email"
                onChange={this._handleChange}
                isError={this._isError('email')}
              />
              {submitButton}
            </Fields>
          </Box>
        </Flex>
      )
    }

    return (
      <Wrap>
        <Flex>
          <Box width={1} mb={2}>
            <PageCopy>Subscribe for updates on projects for sale</PageCopy>
          </Box>
        </Flex>
        <Box>{formContent}</Box>
        <Box mt={2}></Box>
      </Wrap>
    )
  }
}

export default ForSaleSubscribe

const Wrap = styled.div`
  text-align: center;
  margin-bottom: 85px;
  margin-top: 85px;
  @media (max-width: 800px) {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
`

const Note = styled.p`
  font-size: 15px;
  text-align: left;
`
/*
const Overview = styled.div`
  text-align: left;
  font-size: 140%;
  line-height: 140%;
  margin-bottom: 2em;
  strong {
    ${fontBold()};
  }
  @media (max-width: 800px) {
    font-size: 100%;
  }
`
*/

const Fields = styled.div`
  display: flex;
  justify-content: flex-start;
  opacity: ${(props) => (props.isSending ? 0.5 : 1)};
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  p {
    font-size: 120%;
    line-height: 140%;
  }
`

const EmailInput = styled.input`
  background: #fff;
  display: inline-block;
  width: 50%;
  padding: 20px;
  border: ${(props) =>
    props.isError ? `1px solid red` : `1px solid ${theme.colors.grey2}`};
  &:focus {
    border: 1px solid ${theme.colors.green};
    outline: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 5vw;
  }
`

const EmailButton = styled.button`
  display: inline-block;
  padding: 20px;
  margin: 0 10px;
  width: 160px;
  ${fontBold()};
  color: ${theme.colors.white};
  border: none;
  cursor: pointer;
  background: ${(props) => (props.bg ? props.bg : theme.colors.grey1)};
  &:hover {
    background: ${(props) =>
      props.bgHover ? props.bgHover : theme.colors.buttonHover};
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`

const PulseWrap = styled.div`
  display: inline-block;
  position: relative;
  height: 65px;
  margin: 0 10px;
  width: 160px;
  overflow: hidden;
  div {
    position: absolute;
    left: 40px;
    top: -17px;
  }
  @media (max-width: 800px) {
    width: 100%;
    div {
      left: calc(50% - 16px);
    }
  }
`

const Msg = styled.div`
  text-align: left;
  margin-top: 30px;
  font-size: 120%;
`
