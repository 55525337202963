import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme } from '../util/style'

import FooterSocial from './footer-social'
import HappyHausFooterIcon from './svg/happy-haus-footer-icon'
import HeaderLogoSecondary from './svg/header-logo-secondary'
import AnimatedHausLogo from './svg/animated-haus-logo'

class FooterLinks extends Component {
  render() {
    return (
      <>
        <Box px={2}>
          <Divider />
        </Box>
        <Wrap>
          <Box px={2}>
            <AnimatedHausLogo />
          </Box>
          <ListWrapper mt={[3, 0]} mx={2}>
            <List>
              <li>
                <Link to="/designs-custom">Custom Haus</Link>
              </li>
              <li>
                <Link to="/haus-series">Haus Series</Link>
              </li>
              <li>
                <Link to="/inclusions">Specifications</Link>
              </li>
            </List>
            <List>
              <li>
                <Link to="/gallery">Haus Gallery</Link>
              </li>
              <li>
                <Link to="/articles">Articles & Insights</Link>
              </li>
              <li>
                <Link to="/resources">Haus Resources</Link>
              </li>
            </List>
            <List>
              <li>
                <Link to="/philosophy">Why Happy Haus</Link>
              </li>
              <li>
                <Link to="/work-with-us">Work with us</Link>
              </li>
              <li>
                <Link to="/contact">Contact our team</Link>
              </li>
            </List>
          </ListWrapper>
        </Wrap>
      </>
    )
  }
}

export default FooterLinks

const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.grey11};
  margin-bottom: 30px;
`

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 65%;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const ListWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`

const List = styled.div`
  font-family: ${theme.font.fontFamilyRegular};
  font-size: 17px;

  line-height: 210%;

  margin-bottom: 75px;
  li {
    list-style-type: none;
  }
  a {
    color: ${theme.colors.grey1};
    &:hover {
      color: ${theme.colors.grey7};
    }
  }
  @media (max-width: 800px) {
    margin-bottom: 3vw;
  }
`
