import React, { Component } from 'react'
import { Pulse } from 'styled-spinkit'
import { connect } from 'react-redux'
import { setLightboxForm } from '../actions'
import { Link, navigate } from 'gatsby'
import { theme } from '../util/style'
import siteConfig from '../util/site-config'

import JSCookie from 'js-cookie'

import {
  FormWrap,
  FormRow,
  FormColFull,
  FormCol,
  FormTextInput,
  FormItem,
  FormSubmit,
  FormButton,
  PulseWrap,
  FormTextarea,
} from '../util/style'
import * as yup from 'yup'
import Select from 'react-select'
import _ from 'lodash'
import styled from 'styled-components'
import IconCheck from './svg/form-check'
import FormTooltip from './tooltip/FormTooltip'
import { Box } from '@rebass/grid'

const mapDispatchToProps = (dispatch) => ({})

const intentionOptions = [
  {
    id: 0,
    label: 'Which are you?',
    value: '',
  },
  {
    id: 1,
    label: 'Have land and looking to build ASAP',
    value: 'new_haus',
  },
  {
    id: 2,
    label: 'Considering a knockdown rebuild to an existing property',
    value: 'knockdown',
  },
  {
    id: 4,
    label: 'Just learning for later',
    value: 'learning',
  },
]

const preferedVisitOptions = [
  {
    id: 0,
    label: 'Choose your preferred day to visit',
    value: '',
  },
  {
    id: 2,
    label: 'Saturday, 4 November from 2 – 5pm',
    value: 'Saturday, 4 November from 2 – 5pm',
  },
  {
    id: 3,
    label: 'Saturday, 11 November from 2 – 5pm',
    value: 'Saturday, 11 November from 2 – 5pm',
  },
  {
    id: 4,
    label: 'Sunday, 19 November from 2 – 5pm',
    value: 'Sunday, 19 November from 2 – 5pm',
  },
  {
    id: 5,
    label: 'Saturday, 25 November from 2 – 5pm',
    value: 'Saturday, 25 November from 2 – 5pm',
  },
]

class Form extends Component {
  constructor(props) {
    super(props)

    const { category } = this.props

    this.inviteForm = category === 'Open Haus Invite'

    this.state = {
      intention: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      details: '',
      addressPostcode: '',
      open_haus_interested: 'No',
      in_haus_opt_in: 'No',
      errors: {},
      isSending: false,
      isSent: false,
      isIntentionEmpty: false,
    }
    this._onSubmit = this._onSubmit.bind(this)
    this._onFieldChange = this._onFieldChange.bind(this)
    this._onSelectChange = this._onSelectChange.bind(this)
    this._onRadioChange = this._onRadioChange.bind(this)
  }

  _onSubmit() {
    const self = this
    const {
      intention,
      firstName,
      lastName,
      email,
      addressPostcode,
      open_haus_interested,
      in_haus_opt_in,
    } = this.state

    const { category, redirect, dispatch } = this.props

    this.setState({
      isSending: true,
    })

    const validationOptions = {
      abortEarly: false,
    }

    if (intention === '') this.setState({ isIntentionEmpty: true })

    const schema = yup.object().shape({
      hs_persona: yup.string().required('Message...'),
      firstName: yup.string().required('Message...'),
      lastName: yup.string().required('Message...'),
      email: yup.string().required('Message...').email('Must be a valid email'),
      addressPostcode: yup.string().required('Message...'),
    })

    const date = new Date()
    const timestamp = date.getTime()

    let pageUri = 'https://happyhaus.com.au'
    let pageTitle = 'Happy Haus'
    let pageCookie = ''
    if (typeof window !== `undefined`) {
      pageUri = window.location.href
      pageTitle = document.title
      pageCookie = JSCookie.get('hubspotutk')
    }

    const context = {
      hutk: pageCookie,
      pageUri: pageUri,
      pageName: pageTitle,
    }

    const hs_persona = siteConfig.personas[intention]
    const intentionChosen = _.find(intentionOptions, { value: intention }).label

    const data = {
      submittedAt: timestamp,
      context: context,
      intentionChosen: intentionChosen,
      fields: {
        hs_persona: hs_persona,
        firstName: firstName,
        lastName: lastName,
        email: email,
        addressPostcode: addressPostcode,
        conversionPage: pageTitle,
        open_haus_interested,
        in_haus_opt_in,
      },
    }

    schema
      .validate(data.fields, validationOptions)
      .then(function (value) {
        fetch('/.netlify/functions/open-haus-hubspot', {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then(async function (response) {
            if (response.status === 200) {
              self.setState({
                isSending: false,
              })
              dispatch(
                setLightboxForm({
                  category: null,
                  title: null,
                  redirect: null,
                })
              )
              navigate(redirect)
            } else {
              const resText = await response.text()
              console.log('⏰', JSON.parse(resText))
              self.setState({
                ...self.state,
                errors: { submit: JSON.parse(resText).msg },
                isSending: false,
              })
            }
          })
          .catch(function (err) {
            console.log('💀', err.data)
            self.setState({ ...self.state, isSending: false })
          })
      })
      .catch(function (err) {
        const newErrors = {}
        _.forEach(err.inner, function (err) {
          newErrors[err.path] = err.message
        })
        self.setState({ ...self.state, errors: newErrors, isSending: false })
      })
  }

  _onRadioChange(key, val) {
    this.setState({
      [key]: val,
    })
  }

  _onFieldChange(e) {
    const target = e.target
    const key = target.name

    let val

    if (target.type === 'checkbox') {
      val = Boolean(target.checked) ? 'Yes' : 'No'
    } else {
      val = target.value
    }

    this.setState({
      [key]: val,
    })
  }

  _onSelectChange(selected, el) {
    const key = el.name
    const val = selected.value

    console.log(key, val)
    this.setState({
      [key]: val,
    })
  }

  _isChecked(key) {}

  _isError(field) {
    return this.state.errors[field]
  }

  render() {
    const { intention, isSending, errors } = this.state
    const intentionDefault =
      _.find(intentionOptions, { value: intention }) || intentionOptions[0]

    let submitButton
    if (isSending) {
      submitButton = (
        <PulseWrap>
          <Pulse size="32" color={theme.colors.bronze} />
        </PulseWrap>
      )
    } else {
      submitButton = <FormButton onClick={this._onSubmit}>Send</FormButton>
    }

    return (
      <FormWrap>
        <FormRow isSending={isSending}>
          <FormColFull>
            <FormItem isError={this.state.isIntentionEmpty}>
              <Select
                value={intentionDefault}
                name="intention"
                onChange={this._onSelectChange}
                options={intentionOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                defaultMenuIsOpen={false}
              />
            </FormItem>
          </FormColFull>
        </FormRow>

        <FormRow isSending={isSending}>
          <FormCol>
            <FormItem isError={this._isError('firstName')}>
              <FormTextInput
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={this._onFieldChange}
              />
            </FormItem>
          </FormCol>
          <FormCol>
            <FormItem isError={this._isError('lastName')}>
              <FormTextInput
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={this._onFieldChange}
              />
            </FormItem>
          </FormCol>
        </FormRow>

        <FormRow isSending={isSending}>
          <FormColFull>
            <FormItem isError={this._isError('email')}>
              <FormTextInput
                type="text"
                name="email"
                placeholder="Email Address"
                onChange={this._onFieldChange}
              />
            </FormItem>
          </FormColFull>
        </FormRow>

        <FormRow isSending={isSending}>
          <FormColFull>
            <FormItem isError={this._isError('details')}>
              <FormTextarea
                name="details"
                onChange={this._onFieldChange}
                placeholder="Project Details"
              />
            </FormItem>
          </FormColFull>
        </FormRow>

        <FormRow isSending={isSending}>
          <FormColFull>
            <FormItem isError={this._isError('addressPostcode')}>
              <FormTextInput
                type="text"
                name="addressPostcode"
                placeholder="Postcode"
                onChange={this._onFieldChange}
              />
            </FormItem>
          </FormColFull>
        </FormRow>

        <Box mt={['14px', 0]} mb={['28px', 0]}>
          <FormRow isSending={isSending}>
            <FormColFull>
              <FormItem
                mb={['4px', '8px']}
                hideBorder
                isError={this._isError('in_haus_opt_in')}
              >
                <CheckWrap>
                  <input
                    type="checkbox"
                    name="in_haus_opt_in"
                    onChange={this._onFieldChange}
                  />

                  <label>
                    <IconCheck />I want to receive{' '}
                    <FormTooltip
                      tooltipCopy={
                        <>
                          <i>In-haus</i> is our bi-monthly email sharing
                          insights and stories about the happy haus life.
                        </>
                      }
                    >
                      In-haus
                    </FormTooltip>{' '}
                    the bi-monthly newsletter
                  </label>
                </CheckWrap>
              </FormItem>
            </FormColFull>
          </FormRow>

          <FormRow isSending={isSending}>
            <FormColFull>
              <FormItem
                mb={['30px', '60px']}
                hideBorder
                isError={this._isError('open_haus_interested')}
              >
                <CheckWrap>
                  <input
                    type="checkbox"
                    name="open_haus_interested"
                    onChange={this._onFieldChange}
                  />
                  <label>
                    <IconCheck />I want to know about{' '}
                    <FormTooltip
                      tooltipCopy={
                        <>
                          <i>Open Haus</i> is an opportunity to see a range of
                          homes designed + built by Happy Haus throughout the
                          year.{' '}
                          <Link to={'/open-haus'}>
                            <span>Learn more</span>
                          </Link>
                        </>
                      }
                    >
                      Open Haus
                    </FormTooltip>{' '}
                    in the future
                  </label>
                </CheckWrap>
              </FormItem>
            </FormColFull>
          </FormRow>
        </Box>

        <FormSubmit>{submitButton}</FormSubmit>
        {errors.submit && <p style={{ marginTop: '1em' }}>{errors.submit}</p>}
      </FormWrap>
    )
  }
}

export default connect(mapDispatchToProps)(Form)

const CheckWrap = styled.div`
  display: flex;
  position: relative;
  padding-left: 30px;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    top: -2px;
    left: -2px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    z-index: 2;
  }

  input[type='checkbox']:checked + label {
    svg {
      display: block;
    }
  }

  label {
    cursor: pointer;

    &:before {
      left: 0;
      top: 0;
      content: '';
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 0;
      position: absolute;
      border: 1px solid ${theme.colors.grey2};
      cursor: pointer;
    }
  }

  svg {
    display: none;
    position: absolute;
    left: 5px;
    top: 4px;

    @media (max-width: 800px) {
      left: 2px;
      top: 2px;
    }
  }
`
