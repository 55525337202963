import React from 'react'
import { Flex, Box } from '@rebass/grid'
import _ from 'lodash'
import { connect } from 'react-redux'
import Title from './page-title'
import Row from './page-content-row'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'
import PageButtons from '../components/page-buttons'
import siteConfig from '../util/site-config'

const mapDispatchToProps = (dispatch) => ({})

const click = (e, dispatch, title, redirect) => {
  e.preventDefault()
  dispatch(
    setLightboxForm({
      category: '',
      title: title,
      redirect: redirect,
    })
  )
}

const FooterLandingCta = ({ dispatch, slug }) => {
  const page = _.find(siteConfig.landingPages, { slug: slug })

  if (
    !page ||
    !page.ctaTitle ||
    !page.ctaButtonLabel ||
    !page.formRedirect ||
    !page.formTitle
  )
    return null

  return (
    <Row noPad={true}>
      <Flex flexWrap="wrap" flexDirection="row">
        <Box width={[1, 12 / 12]} px={2} alignSelf="flex-start">
          <Title align="center">{page.ctaTitle}</Title>
          <PageButtons
            align="center"
            data={[
              {
                label: page.ctaButtonLabel,
                onClick: (e) => {
                  return click(e, dispatch, page.formTitle, page.formRedirect)
                },
                background: theme.colors.grey1,
              },
            ]}
          />
        </Box>
      </Flex>
    </Row>
  )
}

export default connect(mapDispatchToProps)(FooterLandingCta)
