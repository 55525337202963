import React, { Component } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import DefaultForm from './form-contact'
import OpenHausForm from './open-haus-contact'
import OpenHausSubscribeForm from './open-haus-subscribe-contact'
import { theme, FormTitle } from '../util/style'

import { connect } from 'react-redux'
import { setLightboxForm } from '../actions'

const mapStateToProps = ({
  lightboxFormCategory,
  lightboxFormTitle,
  lightboxFormRedirect,
  lightboxForm,
  lightboxMeta,
  lightboxInitialEmail,
}) => ({
  lightboxFormCategory,
  lightboxFormTitle,
  lightboxFormRedirect,
  lightboxForm,
  lightboxMeta,
  lightboxInitialEmail,
})

const mapDispatchToProps = (dispatch) => ({
  setLightboxForm: (lightboxForm) => dispatch(setLightboxForm(lightboxForm)),
})

class LightboxForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      Form: DefaultForm,
    }
    this.overlayRef = React.createRef()
    this._handleOverlayClick = this._handleOverlayClick.bind(this)
    this._handleCloseClick = this._handleCloseClick.bind(this)
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    const { lightboxFormCategory, lightboxFormTitle, lightboxForm } = nextProps

    if (
      lightboxFormCategory !== this.props.lightboxFormCategory &&
      lightboxFormCategory === null
    ) {
      this.setState({
        isActive: false,
      })
      document.body.classList.toggle('lightboxActive', false)
    } else {
      if (lightboxFormTitle !== this.props.lightboxFormTitle) {
        this.setState({
          isActive: true,
        })
        document.body.classList.toggle('lightboxActive', true)
      }
    }

    switch (lightboxForm) {
      case 'open-haus':
        this.setState({
          Form: OpenHausForm,
        })
        break
      case 'open-haus-subscribe':
        this.setState({
          Form: OpenHausSubscribeForm,
        })
        break
      default:
    }
  }

  _handleCloseClick(e) {
    e.preventDefault()
    this.props.setLightboxForm({
      category: null,
      title: null,
      redirect: null,
    })
  }

  _handleOverlayClick(e) {
    if (this.overlayRef.current === e.target) {
      e.preventDefault()
      this.props.setLightboxForm({
        category: null,
        title: null,
        redirect: null,
      })
    }
  }

  render() {
    const { isActive } = this.state
    const {
      lightboxFormTitle,
      lightboxFormCategory,
      lightboxFormRedirect,
      lightboxMeta,
      lightboxInitialEmail,
    } = this.props
    if (isActive) {
      return (
        <Overlay
          ref={this.overlayRef}
          onClick={(e) => this._handleOverlayClick(e)}
        >
          <GlobalStyle />
          <Wrap>
            <MobileClose>
              <button onClick={(e) => this._handleCloseClick(e)}>Close</button>
            </MobileClose>
            <FormTitle
              dangerouslySetInnerHTML={{ __html: lightboxFormTitle }}
            />
            <this.state.Form
              category={lightboxFormCategory}
              redirect={lightboxFormRedirect}
              meta={lightboxMeta}
              initialEmail={lightboxInitialEmail}
            />
          </Wrap>
        </Overlay>
      )
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightboxForm)

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(200, 200, 200, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  @media (max-width: 800px) {
    overflow-y: scroll;
    scroll-behavior: smooth;
    background: ${theme.colors.white};
  }
`

const Wrap = styled.div`
  background: ${theme.colors.white};
  width: 800px;
  @media (max-width: 800px) {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`

const GlobalStyle = createGlobalStyle`
  body.lightboxActive {
		@media (max-width: 800px) {
			overflow: hidden;
		}
  }
`

const MobileClose = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
  display: none;
  font-size: 115%;
  button {
    border: none;
    outline: none;
    padding: 0;
    background: none;
    color: ${theme.colors.grey1};
    cursor: pointer;
  }
  @media (max-width: 800px) {
    display: block;
  }
`
