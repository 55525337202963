import React from 'react'

export default () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-0.5"
        x2="23.3833"
        y2="-0.5"
        transform="matrix(0.686624 0.727013 -0.686624 0.727013 0 1)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="23.3833"
        y2="-0.5"
        transform="matrix(0.686624 -0.727013 0.686624 0.727013 0.944443 18)"
        stroke="white"
      />
    </svg>
  )
}
