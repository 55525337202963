import { Box } from '@rebass/grid'
import styled from 'styled-components'

export const theme = {
  letterSpacing: (val = 1) => `${(parseInt(val) * 0.006).toFixed(3)}em`,
  articlesTheme: {
    A1: {
      background: {
        primary: '#31333A',
      },
      text: {
        primary: '#81868B',
      },
    },
    A2: {
      background: {
        primary: '#81868B',
      },
      text: {
        primary: '#31333A',
      },
    },
    B1: {
      background: {
        primary: '#A59987',
      },
      text: {
        primary: '#EADBC0',
      },
    },
    B2: {
      background: {
        primary: '#EADBC0',
      },
      text: {
        primary: '#A59987',
      },
    },
  },

  articleSubscribeRowTheme: {
    'Subtropical Homes': {
      border: {
        primary: '#C68D78',
      },
      text: {
        primary: '#68443C',
      },
    },
    'Single Level Living': {
      border: {
        primary: '#ABC17A',
      },
      headingText: {
        primary: '#3E6F42',
      },
      text: {
        primary: '#ABC17A',
      },
    },
    'Considered Floorplans': {
      border: {
        primary: '#7AA7CB',
      },
      text: {
        primary: '#7AA7CB',
      },
    },
  },

  colors: {
    grey1: `#31333A`,
    grey2: `#DADADA`,
    grey3: `#9F9F9F`,
    grey4: `#E8E8E8`,
    grey5: `#777`,
    grey6: `#EFEFEF`,
    grey7: `#81868B`,
    grey8: '#B8B8B8',
    grey9: '#A9A9A9',
    grey10: '#333333',
    grey11: '#ABBDC8',
    bronze: `#C5A47E`,
    yellow: `#FFF000`,
    yellow1: `#FFD466`,
    red1: `#68443C`,
    mint: `#D3FFEE`,
    green: `#847E4D`,
    green2: `#C4D39B`,
    green3: `#3E6F42`,
    white: `#ffffff`,
    buttonHover: `#222222`,
  },
  font: {
    fontFamilyLight: `'atlas-light', 'sans-serif'`,
    fontFamilyRegular: `'atlas-regular', 'sans-serif'`,
    fontFamilyItalic: `'atlas-regular-italic', 'sans-serif'`,
    fontFamilyBold: `'atlas-bold', 'sans-serif'`,
    fontFamilyMedium: `'atlas-medium', 'sans-serif'`,
    fontFamilyThin: `'atlas-thin', 'sans-serif'`,
    fontFamilySerifThin: `'martina-plantijn-light', 'serif'`,
    fontFamilySerifItalic: `'martina-plantijn-italic', 'serif'`,
    fontFamilyABCLight: `'abc-diatype-extended-light', 'serif'`,
    sizes: {
      sm: `17px`,
      md: `20px`,
      lg: `20px`,
    },
  },
  breakpoints: [`800px`, `1100px`, `1500px`],
  dimensions: {
    maxWidth: `1440px`,
  },
}

export function fontLight() {
  return `
    font-family: ${theme.font.fontFamilyLight};
    font-weight: normal;
  `
}

export function fontRegular() {
  return `
    font-family: ${theme.font.fontFamilyRegular};
    font-weight: normal;
  `
}

export function fontItalic() {
  return `
    font-family: ${theme.font.fontFamilyItalic};
    font-weight: normal;
  `
}

export function fontBold() {
  return `
    font-family: ${theme.font.fontFamilyBold};
    font-weight: normal;
  `
}

export function fontThin() {
  return `
    font-family: ${theme.font.fontFamilyThin};
    font-weight: normal;
  `
}

export function responsiveFont() {
  return `
    @media (max-width: 3000px) {
      font-size: ${theme.font.sizes.lg};
      line-height: 150%;
    }
    @media (max-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.font.sizes.md};
      line-height: 145%;
    }
    @media (max-width: ${theme.breakpoints[0]}) {
      font-size: ${theme.font.sizes.sm};
      line-height: 145%;
    } 
  `
}

/* Form */

export const FormTitle = styled.div`
  padding: 55px 20px 55px 20px;
  font-size: 40px;
  line-height: 130%;
  color: ${theme.colors.grey1};
  text-align: center;
  @media (max-width: 800px) {
    color: ${theme.colors.grey1};
    padding: 30px 20px 27px 20px;
  }
  font-family: ${theme.font.fontFamilySerifThin};
`

export const FormWrap = styled.div`
  padding: 30px;
  padding-top: 0;
  padding-bottom: 50px;

  @media (max-width: 800px) {
    padding-bottom: 40px;
  }
`

export const FormRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  opacity: ${(props) => (props.isSending ? 0.5 : 1)};
  @media (max-width: 800px) {
    flex-wrap: wrap;
    gap: 0;
  }
`

export const FormCol = styled.div`
  width: 100%;
`

export const RadioWrap = styled.div`
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 11px;
  input {
    opacity: 0;
    position: absolute;
    top: -2px;
    left: -2px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    z-index: 2;
  }
  label {
    color: ${theme.colors.grey1};
  }
  label:before {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #a1a1a1;
    cursor: pointer;
  }
  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    display: none;
    height: 12px;
    width: 12px;
    background-color: #4a4a4a;
    border-radius: 50%;
  }
  input:checked + label:after {
    display: block;
  }
`

export const PulseWrap = styled.div`
  position: relative;
  height: 80px;
  overflow: hidden;
  div {
    position: relative;
    top: -20px;
  }
`

export const FormColFull = styled.div`
  width: 100%;
`

export const FormItem = styled(Box)`
  border: 3px solid ${(props) => (props.isError ? '#FF8A5F' : 'transparent')};
  margin-bottom: 20px;

  font-family: ${theme.font.fontFamilyRegular};

  font-size: 19px;
  @media (max-width: 800px) {
    margin-bottom: 14px;
    font-size: 18px;
  }

  @media (max-width: 800px) {
    border: 1px solid
      ${(props) =>
        props.hideBorder
          ? 'transparent'
          : props.isError
          ? '#FF8A5F'
          : theme.colors.grey4};
  }

  .react-select__control {
    border-radius: 0px;
    border: none;
    background: ${theme.colors.yellow1};

    height: 70px;
    @media (max-width: 800px) {
      height: 55px;
    }

    cursor: pointer;
    &:hover {
      border: 1px solid ${theme.colors.grey1};
    }
  }
  .react-select__control.react-select__control--is-focused {
    box-shadow: none;
  }
  .react-select__control.react-select__control--menu-is-open {
    border: 1px solid ${theme.colors.grey1};
    font-family: ${theme.font.fontFamilyRegular};
  }
  .react-select__value-container {
    padding: 12px 12px;
  }
  .react-select__single-value {
    color: ${theme.colors.grey1};
  }
  .react-select__indicators {
  }
  .react-select__indicator {
    color: ${theme.colors.white};
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__menu {
    border-radius: 0px;
  }
  .react-select__menu-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .react-select__option {
    cursor: pointer;
  }
  .react-select__option.react-select__option--is-selected {
    background: ${theme.colors.grey2};
  }
  .react-select__option.react-select__option--is-selected.react-select__option--is-focused {
    background: ${theme.colors.grey2};
  }
  .react-select__option.react-select__option--is-focused {
    background: ${theme.colors.grey4};
  }
`

export const FormTextInput = styled.input`
  width: 100%;
  padding: 18px 13px;
  background: ${theme.colors.white};
  outline: none;
  border: 1px solid ${theme.colors.grey11};
  -webkit-appearance: none;
  color: ${theme.colors.grey1};
  ::-webkit-input-placeholder {
    color: ${theme.colors.grey7};
  }

  height: 70px;
  @media (max-width: 800px) {
    height: 55px;
    border: none;
  }

  border-radius: 0;
`

export const FormTextarea = styled.textarea`
  width: 100%;
  padding: 18px 13px;
  background: ${theme.colors.white};
  outline: none;
  border: 1px solid ${theme.colors.grey11};
  -webkit-appearance: none;
  color: ${theme.colors.grey1};
  height: 150px;
  ::-webkit-input-placeholder {
    color: ${theme.colors.grey7};
  }
  @media (max-width: 800px) {
    border: none;
  }

  display: block;
  border-radius: 0 !important;
`

export const FormSubmit = styled.div`
  text-align: center;
`

export const FormButton = styled.div`
  display: inline-block;
  padding: 20px 40px;
  outline: none;
  border: none;
  color: ${theme.colors.white};
  background: ${(props) => (props.bg ? props.bg : theme.colors.grey1)};
  &:hover {
    background: ${(props) =>
      props.bgHover ? props.bgHover : theme.colors.buttonHover};
  }
  cursor: pointer;
`

export const pageCopy = () => {
  return `
    .gatsby-resp-image-link {
      border: none !important;
    }


    @media only screen and (max-width: 600px) {
      .side-by-side {
        flex-direction: column;
      }
      & div {
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }

    .side-by-side {
      display: none;
    }
    
    h2 {
      ${fontBold()};
      font-size: 114%;
      margin-top: 2em;
    }
    a {
      color: ${theme.colors.grey1};
      border-bottom: 2px solid ${theme.colors.grey11};
    }

    b, strong {
      ${fontBold()};
    }

    blockquote {
      border-left-style: solid;
      padding-left: 20px;
    }

    video {
      width: 350px;
      height: auto; 
      margin-bottom: 1em;
      position: relative;
      display: block;
    }

    @media (max-width: 500px) {
      video {
        margin-inline: auto;
        width: 90%;
      }
    }

    p > em {
      display: inline-block;
      margin-top: 2px;
      line-height 143%;
      color: ${theme.colors.grey3};
      font-style: normal;
      font-size: 0.9rem;
    }

    p > em:first-of-type {
      margin-top: 10px;
    }

     
  `
}

export const OutlineButton = styled.button`
  color: ${theme.colors.grey1};
  background-color: transparent;
  border: 1px solid #abbdc8;
  font-size: 17px;
  font-family: ${theme.font.fontFamilyRegular};
  padding: 10px 26px;
  margin-top: 80px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 800px) {
    margin-top: 40px;
    padding: 12px 22px;
  }
`
