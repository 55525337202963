import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fontLight, fontRegular } from '../util/style'
import { Box } from '@rebass/grid'

const PageTitle = ({
  align,
  mega,
  children,
  grey,
  noMargin,
  noPaddingTop,
  color,
  ...rest
}) => (
  <Title
    align={align}
    mega={mega}
    grey={grey}
    noMargin={noMargin}
    noPaddingTop={noPaddingTop}
    color={color}
    {...rest}
  >
    {children}
  </Title>
)

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
}

export default PageTitle

const Title = styled(Box)`
  ${(props) => (props.mega ? fontLight() : fontRegular())};
  font-size: ${(props) => (props.mega ? `200%` : `30px`)};
  line-height: 140%;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-bottom: 20px;
  @media (max-width: 800px) {
    font-size: ${(props) => (props.mega ? `320%` : `130%`)};
    line-height: 120%;
    padding-top: ${({ noPaddingTop }) => (noPaddingTop ? `0` : `20px`)};
  }

  ${({ grey }) => (grey ? `color: #9F9F9F;` : ``)};
  ${({ noMargin }) => (noMargin ? `margin: 0 !important` : ``)};
  ${({ color }) => (color ? `color: ${color} !important` : ``)};
`
