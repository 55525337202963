import React, { useState, useEffect, useRef } from 'react'

const fillWhite = '#ffffff'
const fillBlack = '#31333A'

const marks = (fill) => [
  <svg
    width="50"
    height="29"
    viewBox="0 0 50 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M50 0V29H0V0H50Z" fill={fill} />
  </svg>,
  <svg
    width="50"
    height="29"
    viewBox="0 0 50 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M50 6.72567V29H0V6.72567L25 0L50 6.72567Z" fill={fill} />
  </svg>,
  <svg
    width="50"
    height="29"
    viewBox="0 0 50 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M50 0.00281306V29H0V6.58398L50 0V0.00281306Z" fill={fill} />
  </svg>,
]

const HausLogo = ({ currMark, marks }) => {
  return marks[currMark]
}

const AnimatedHausLogo = ({ inverted }) => {
  const fill = inverted ? fillWhite : fillBlack
  const [currMark, setCurrMark] = useState(0)
  const svgMarks = marks(fill)

  const intervalRef = useRef()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrMark((prevMark) =>
        prevMark + 1 === svgMarks.length ? 0 : prevMark + 1
      )
    }, 500)
    return () => clearInterval(intervalRef.current)
  }, [svgMarks])

  return <HausLogo currMark={currMark} marks={svgMarks} />
}

export default AnimatedHausLogo
