import React from 'react'
import styled from 'styled-components'
import { theme, responsiveFont, fontRegular } from '../../util/style'

const Wrap = ({ children }) => {
  return (
    <El>
      <Inner>{children}</Inner>
    </El>
  )
}

export default Wrap

const El = styled.div`
  background: ${theme.colors.grey1};

  ${fontRegular()};
  ${responsiveFont()};

  padding: 5.4em 1.6em;
  @media (max-width: 800px) {
    padding: unset;
  }

  a {
    text-decoration: none;
  }
  p {
    margin-top: 0px;
  }
  @media (max-width: 1100px) {
    /* padding: 0 3vw; */
  }
  @media (max-width: 800px) {
    /* padding: 0 10px; */
  }
  height: 100vh;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 816px;
  @media (max-width: 800px) {
    max-width: unset;
  }

  margin-left: auto;
  margin-right: auto;
`
