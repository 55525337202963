import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { theme, fontRegular } from '../util/style'
import { Link } from 'gatsby'
import Cookies from 'js-cookie'
import Cross from '../components/svg/icon-cross'
import { Box } from '@rebass/grid'

export default () => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const currentCookie = Cookies.get('showBanner')
    if (currentCookie === undefined) {
      setShowBanner(true)
    }
  })

  const close = () => {
    setShowBanner(false)
    Cookies.set('showBanner', 'true', { expires: 7 })
  }
  if (showBanner) {
    return (
      <>
        <Banner>
          <Wrap>
            <Link
              style={{ textDecoration: 'none' }}
              to="/article-top-sales-results-in-new-farm-and-tarragindi"
            >
              <span>Top sales results in New Farm and Tarragindi</span>
            </Link>
            <Box as="button" onClick={close} px={2}>
              <Cross />
            </Box>
          </Wrap>
        </Banner>
        <Divider />
      </>
    )
  } else {
    return null
  }
}

const Divider = styled(Box)`
  width: 100%;
  position: absolute;
  height: 1px;
  background-color: ${theme.colors.grey11};
`

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: ${theme.dimensions.maxWidth};
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  @media (max-width: 800px) {
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  span {
    letter-spacing: ${theme.letterSpacing(-1)};
  }

  svg {
    line {
      stroke: ${theme.colors.grey11};
    }
  }
`

const Banner = styled.div`
  height: 75px;
  line-height: 140%;
  font-family: ${theme.font.fontFamilyRegular};
  font-size: 17px;
  top: 0;
  left: 0;
  padding: 0 5vw;
  width: 100%;
  background: transparent;
  z-index: 9999;
  text-align: center;
  color: ${theme.colors.grey1};
  a {
    color: ${theme.colors.grey1};
    text-decoration-thickness: 1px;
  }
  button {
    cursor: pointer;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0;
    background: transparent;
    outline: none;
    border: none;
    padding: none;
    color: ${theme.colors.white};

    svg {
      width: 14px;
    }

    @media (max-width: 800px) {
      height: auto;
      padding-top: 10px;
    }
  }
  @media (max-width: 1100px) {
    padding: 0 3vw;
  }

  @media (max-width: 800px) {
    font-size: 75%;
    padding: 0 4px;
    height: 70px;
  }
`
