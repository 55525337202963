import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'

const HeaderLogoSecondary = () => {
  return (
    <Wrapper>
      <svg
        width="86"
        height="53"
        viewBox="0 0 86 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M86 0V53H0V22.9461L86 0Z" fill="#303030" />
      </svg>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  width: 86px;
  height: 53px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 800px) {
    width: 43px;
    height: 27px;
  }
`

export default HeaderLogoSecondary
