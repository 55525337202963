import React from 'react'
import styled from 'styled-components'
import { theme, responsiveFont, fontRegular } from '../util/style'
import { Flex, Box } from '@rebass/grid'
import ForSaleSubscribe from './for-sale-subscribe'
import FooterSocial from './footer-social'
import FooterTerms from './footer-terms'

const ForSaleWrap = ({ children }) => {
  return (
    <Wrapper px={['22px', 3]}>
      {children}{' '}
      <FooterWrapper>
        <Box />
        <Box>
          <ForSaleSubscribe />
          <FooterSocial isLanding="flex-start" />
          <FooterTerms noPadding isLanding="flex-start" />
        </Box>
      </FooterWrapper>
    </Wrapper>
  )
}

export default ForSaleWrap

const Wrapper = styled(Box)`
  max-width: ${theme.dimensions.maxWidth};
  margin: 0 auto;
  z-index: 0;
  ${fontRegular}
`
const FooterWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 30% 70%;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`
