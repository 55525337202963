import React, { useState, useEffect, Component } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme, FormTitle } from '../util/style'
import ContactForm from './form-contact'
import BgImg from '../images/etc/happyhaus-form-bg.jpg'

const FooterContact = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 3000)
  }, [])

  return (
    <Wrap>
      <Flex>
        <InnerWrapper width={1} px={[0, 2]}>
          <FormWrap visible={visible}>
            <FormBox>
              <FormTitle>Let’s talk</FormTitle>
              <ContactForm category="Contact" redirect="/contact-thank-you" />
            </FormBox>
          </FormWrap>
        </InnerWrapper>
      </Flex>
    </Wrap>
  )
}

export default FooterContact

const Wrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 800px) {
    padding-top: 76px;
    padding-bottom: 60px;
  }
`

const InnerWrapper = styled(Box)`
  position: relative;

  @media (max-width: 800px) {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    // Remove any horizontal padding if present
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

const FormWrap = styled.div`
  background: ${({ visible }) =>
    visible ? `url(${BgImg}) no-repeat center center;` : `none`};
  background-size: cover;
  padding-top: 47px;
  padding-bottom: 67px;
  padding-left: 200px;
  padding-right: 200px;

  @media (max-width: 1260px) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @media (max-width: 1000px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 800px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`

const FormBox = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  margin: 0 auto;
`
