import React from 'react'
import styled from 'styled-components'

import IconFacebook from './svg/icon-facebook'
import IconInstagram from './svg/icon-instagram'
import IconYoutube from './svg/icon-youtube'
import IconPinterest from './svg/icon-pinterest'
import { Box } from '@rebass/grid'

const FooterSocial = ({ isLanding = 'center' }) => {
  return (
    <Social align={isLanding ? isLanding : 'flex-end'} px={2}>
      <a href="https://www.pinterest.com.au/happy_haus/" target="_blank">
        <IconPinterest />
      </a>
      <a
        href="https://www.youtube.com/channel/UCf6xffRZEc_QTFWLnh1Z9MQ"
        target="_blank"
      >
        <IconYoutube />
      </a>
      <a href="https://www.facebook.com/happyhausaustralia/" target="_blank">
        <IconFacebook />
      </a>
      <a href="https://instagram.com/happy_haus" target="_blank">
        <IconInstagram />
      </a>
    </Social>
  )
}

export default FooterSocial

const Social = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.align};
  margin-bottom: 2em;
  a {
    display: inline-block;
    margin-left: 10px;
    &:first-of-type {
      margin-left: 0;
    }
  }
  @media (max-width: 800px) {
    justify-content: ${(props) =>
      props.align === 'center' ? 'center' : 'flex-start'};
    a {
      display: inline-block;
      margin-right: 10px;
      margin-left: 0px;
    }
  }
`
