import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import { theme } from '../util/style'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import LogoMark from './svg/happy-haus-wordmark'
import HeaderLogoSecondary from './svg/header-logo-secondary'

export default class HeaderMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this._openMenu = this._openMenu.bind(this)
    this._closeMenu = this._closeMenu.bind(this)
  }

  _openMenu(e) {
    e.preventDefault()
    this.setState({
      menuOpen: true,
    })
  }

  _closeMenu(e) {
    this.setState({
      menuOpen: false,
    })
  }

  render() {
    const { menuOpen } = this.state
    let menuContent

    if (menuOpen) {
      menuContent = (
        <Overlay>
          <Wrap>
            <Flex>
              <Box width={1} px={'5px'}>
                <TitleOpen>
                  <Link to="/" onClick={this._closeMenu}>
                    <LogoMark inverted={true} isMobile={true} />
                  </Link>
                </TitleOpen>
              </Box>
            </Flex>
            <Flex>
              <Box width={1} px={'16px'}>
                <Nav>
                  <ul>
                    <li>
                      <Link to="/haus-series" onClick={this._closeMenu}>
                        Design
                      </Link>
                      <ul>
                        <li>
                          <Link to="/haus-series" onClick={this._closeMenu}>
                            Haus Series
                          </Link>
                        </li>
                        <li>
                          <Link to="/custom-haus" onClick={this._closeMenu}>
                            Custom Haus
                          </Link>
                        </li>
                        <li>
                          <Link to="/specifications" onClick={this._closeMenu}>
                            Specifications
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/gallery" onClick={this._closeMenu}>
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/articles" onClick={this._closeMenu}>
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link to="/resources" onClick={this._closeMenu}>
                        Resources
                      </Link>
                    </li>
                    <li>
                      <Link to="/philosophy" onClick={this._closeMenu}>
                        Philosophy
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={this._closeMenu}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </Nav>
              </Box>
            </Flex>
          </Wrap>
          <TriggerClose onClick={this._closeMenu}>
            <span />
          </TriggerClose>
        </Overlay>
      )
    }

    return (
      <>
        <Fragment>
          <Wrap>
            <Flex>
              <Box width={1} px={2}>
                <Title>
                  <Link to="/" onClick={this._closeMenu}>
                    <LogoMark isMobile={false} />
                  </Link>
                </Title>
              </Box>
            </Flex>
            <TriggerOpen onClick={this._openMenu}>
              <span />
            </TriggerOpen>
          </Wrap>
          {menuContent}
        </Fragment>
      </>
    )
  }
}

const Wrap = styled.header`
  max-width: ${theme.dimensions.maxWidth};
  margin: 0 auto;
  color: ${theme.colors.grey1};
  z-index: 1;
  position: relative;
  font-size: 120%;
  margin-top: 26px;

  a {
    color: ${theme.colors.grey1};
  }

  @media (max-width: 375px) {
    font-size: 90%;
    line-height: 0.8;
  }
`

const Title = styled.h1`
  margin: 0;
  padding: 3px 0px 20px;

  a {
    display: inline-block;
    position: relative;
  }
`

const TitleOpen = styled.h1`
  margin: 0;
  padding: 3px 2px 35px;
  padding-left: 12px;

  a {
    display: inline-block;
    position: relative;
  }
`

const TriggerOpen = styled.button`
  padding-left: 12px;
  position: absolute;
  right: 12px;
  top: 24px;
  outline: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 99;
  background: transparent;
  &::after,
  &::before,
  span {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: ${theme.colors.grey1};
  }
  &::after {
    position: absolute;
    left: 0;
    top: 7px;
  }
  &::before {
    position: absolute;
    left: 0;
    top: 14px;
  }
`

const TriggerClose = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  outline: none;
  border: none;
  width: 30px;
  height: 30px;
  margin-top: 26px;
  cursor: pointer;
  background: transparent;
  z-index: 99;
  &::after,
  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 11px;
    display: block;
    width: 100%;
    height: 2px;
    background: ${theme.colors.white};
  }
  &::after {
    position: absolute;
    left: 0;
    transform: rotate(45deg);
  }
  &::before {
    position: absolute;
    left: 0;
    transform: rotate(-45deg);
  }
`

const Overlay = styled.div`
  background: ${theme.colors.grey1};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`

const Nav = styled.div`
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0 0 7px 0;
    padding: 0;
  }
  ul li ul {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ul li ul li a {
    opacity: 0.6;
  }
  a {
    color: ${theme.colors.white};
    display: block;
    padding: 5px 0;
  }
`
