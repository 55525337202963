import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../util/style'
import { Box } from '@rebass/grid'

const PageContentRow = ({ children, id, noPad, mb, ...rest }) => (
  <Wrap id={id} noPad={noPad} mb={mb} {...rest}>
    {children}
  </Wrap>
)

PageContentRow.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContentRow

const Wrap = styled(Box)`
  margin-top: 0px;
  margin-bottom: ${(props) => (props.noPad === true ? `20px` : `100px`)};
  @media (max-width: 800px) {
    margin-top: 0vw;
    margin-bottom: ${(props) => (props.noPad === true ? `20px` : `9vw`)};
  }
`
