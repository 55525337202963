import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme } from '../util/style'
import IconPinterest from './svg/icon-pinterest'
import IconFacebook from './svg/icon-facebook'
import IconInstagram from './svg/icon-instagram'

const FooterTerms = ({ isLanding = 'center', noPadding }) => {
  return (
    <Wrap align={isLanding ? isLanding : 'left'}>
      <Left>
        <Box width={1} px={noPadding ? 0 : 2}>
          <Footnote>
            ©2024 Happy Haus® Property Group{' '}
            <FootNoteTerms>QBCC 15244958</FootNoteTerms>
          </Footnote>{' '}
        </Box>
      </Left>
      <Right mx={2}>
        <DisclamerWrapper>
          <Link to="/disclaimer">Disclaimer</Link> •{' '}
          <Link to="/privacy">Privacy</Link>
        </DisclamerWrapper>
        <EmptyBox />
        <SocialsWrapper>
          <a
            href="https://www.facebook.com/happyhausaustralia/"
            target="_blank"
          >
            <IconFacebook />
          </a>
          <a href="https://www.pinterest.com.au/happy_haus/" target="_blank">
            <IconPinterest />
          </a>
          <a href="https://instagram.com/happy_haus" target="_blank">
            <IconInstagram />
          </a>
        </SocialsWrapper>
      </Right>
    </Wrap>
  )
}

export default FooterTerms

const Wrap = styled.div`
  padding-bottom: 20px;
  text-align: ${(props) => props.align};

  font-size: 17px;
  margin-bottom: 1em;
  font-family: ${theme.font.fontFamilyRegular};

  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin-top: 32px;
  }
`

const Footnote = styled.div`
  a {
    color: ${theme.colors.grey3};
    &:hover {
      color: ${theme.colors.grey2};
    }
  }
`

const FootNoteTerms = styled(Box)`
  display: inline;

  @media (max-width: 800px) {
    display: block;
  }
`

const SocialsWrapper = styled(Box)`
  display: flex;
  align-items: center;

  gap: 12px;

  a {
    display: block;
    width: 25px;
    height: 25px;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  @media (max-width: 800px) {
    order: 1;
    margin-bottom: 32px;
  }
`
const Left = styled(Box)`
  @media (max-width: 800px) {
    order: 2;
  }
`

const DisclamerWrapper = styled(Box)`
  @media (max-width: 800px) {
    order: 2;
  }

  a {
    color: ${theme.colors.grey1};
  }
`
const EmptyBox = styled(Box)``

const Right = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
