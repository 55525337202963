import React from 'react'
import { theme } from '../util/style'
import styled from 'styled-components'
import FooterSubscribe from './footer-subscribe'
import FooterInstagram from './footer-instagram'
import FooterContact from './footer-contact'
import FooterLinks from './footer-links'
import FooterTerms from './footer-terms'
import FooterSocial from './footer-social'
import FooterLandingCta from './footer-landing-cta'
import siteConfig from '../util/site-config'
import _ from 'lodash'
import { Box } from '@rebass/grid'

const Footer = ({ landing, slug }) => {
  return landing === true ? (
    <FooterLanding slug={slug} />
  ) : (
    <FooterRegular slug={slug} />
  )
}

const FooterRegular = ({ slug }) => {
  const thankYouPages = [
    'builder-checklist-thank-you',
    'build-guides-thank-you',
    'guide-knockdown-rebuild-thank-you',
    'guide-subtropical-homes-thank-you',
  ]

  const form =
    slug === 'contact' ||
    slug === 'contact-thank-you' ||
    thankYouPages.indexOf(slug) > -1 ? null : (
      <FooterContact />
    )

  const subscribe =
    slug === 'contact-thank-you' || thankYouPages.indexOf(slug) > -1 ? null : (
      <FooterSubscribe />
    )

  return (
    <Wrap>
      {form}
      {subscribe}
      <FooterLinks />
      <FooterTerms isLanding={false} />
    </Wrap>
  )
}

const FooterLanding = ({ slug, visible }) => {
  const page = _.find(siteConfig.landingPages, { slug })
  return (
    <Wrap>
      <FooterLandingCta slug={slug} />
      {!page.noPhone && <Phone>Call (07) 3186 5771</Phone>}
      <FooterSocial isLanding={true} />
      <FooterTerms isLanding={true} />
    </Wrap>
  )
}

export default Footer

const Wrap = styled.footer`
  max-width: ${theme.dimensions.maxWidth};
  margin: 0 auto;
  color: ${theme.colors.highlight};
  a {
    color: ${theme.colors.highlight};
  }
`

const Phone = styled.div`
  text-align: center;
  margin: 1em 0 3em 0;
`
