import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import { theme, fontRegular } from '../util/style'

class PageButtons extends Component {
  render() {
    const { align, inCopy } = this.props

    const alignStyle = align ? align : 'left'
    const buttons = this.props.data.map(function (item, i) {
      const key = `button-${i}`

      if (item.external) {
        return (
          <CtaButton
            key={key}
            bg={item.background}
            bgHover={item.backgrounHover}
            align={alignStyle}
            onClick={item.onClick}
          >
            <a href={item.link} target="_blank">
              {item.label}
            </a>
          </CtaButton>
        )
      } else if (!item.link && item.onClick) {
        return (
          <CtaButton
            key={key}
            bg={item.background}
            bgHover={item.backgrounHover}
            align={alignStyle}
            onClick={item.onClick}
          >
            <button href="#">{item.label}</button>
          </CtaButton>
        )
      } else {
        return (
          <CtaButton
            key={key}
            bg={item.background}
            bgHover={item.backgrounHover}
            align={alignStyle}
          >
            <Link to={item.link}>{item.label}</Link>
          </CtaButton>
        )
      }
    })

    return (
      <Wrap align={alignStyle} inCopy={inCopy}>
        {buttons}
      </Wrap>
    )
  }
}

export default PageButtons

const Wrap = styled.div`
  text-align: ${(props) => props.align};
  margin-top: ${(props) => (props.inCopy ? `1em` : `40px`)};
  margin-bottom: ${(props) => (props.inCopy ? `1em` : `0px`)};
  width: 100%;
  @media (max-width: 800px) {
    margin-top: 0px;
  }
  a {
    border-bottom: none !important;
  }
`

const CtaButton = styled.div`
  display: inline-block;
  ${fontRegular()};
  a,
  button {
    display: block;
    padding: 20px;
    line-height: 120%;
    outline: none;
    border: none;
    cursor: pointer;
    margin: ${(props) => (props.align === 'left' ? '0 10px 0 0' : '0 10px')};
    color: ${theme.colors.white} !important;
    background: ${(props) => (props.bg ? props.bg : theme.colors.grey1)};
    &:hover {
      background: ${(props) =>
        props.bgHover ? props.bgHover : theme.colors.buttonHover};
    }
    @media (max-width: 800px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media (max-width: 800px) {
    display: block;
    margin-top: 5vw;
    margin-bottom: 5vw;
    text-align: center;
    button {
      width: 100%;
    }
  }
`
