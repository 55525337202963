import { createStore as reduxCreateStore } from 'redux'

const reducer = (state, action) => {
  if (action.type === 'SET_LIGHTBOX_FORM') {
    return Object.assign({}, state, {
      lightboxFormCategory: action.lightboxForm.category,
      lightboxFormTitle: action.lightboxForm.title,
      lightboxFormRedirect: action.lightboxForm.redirect,
      lightboxInitialEmail: action.lightboxForm.initialEmail,
      lightboxForm: action.lightboxForm.form,
      lightboxMeta: action.lightboxForm.meta,
    })
  }

  if (action.type === 'SET_FORM_DATA') {
    return Object.assign({}, state, {
      formData: action.formData,
    })
  }

  return state
}

const initialState = {
  lightboxFormCategory: null,
  lightboxFormTitle: null,
  lightboxFormRedirect: null,
  formData: {},
}

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore
