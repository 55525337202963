import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { theme, responsiveFont, fontRegular } from '../util/style'
import siteConfig from '../util/site-config'
import LightboxForm from '../components/lightbox-form'
import LinksPageWrap from '../components/links/wrap'
import RegistrationPageWrap from '../components/registration/wrap'
import _ from 'lodash'

import '../css/base.css'
import '../css/fonts.css'

import Header from './header'
import Footer from './footer'
import Announcement from '../components/announcement'
import ForSalePageWrapper from './ForSalePageWrapper'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const gridTheme = {
  space: [0, 8, 12, 30, 40],
  breakpoints: [`800px`, `1100px`, `1500px`],
}

const isLandingPage = (path) => {
  const slugs = _.map(siteConfig.landingPages, 'slug')
  const slug = path.split('/')[1]
  return slug && slugs.indexOf(slug) > -1 && path !== '/'
}

class Layout extends React.Component {
  render() {
    const { children, uri, location, pageContext } = this.props

    const path = location.pathname

    const childrenWithProps = React.Children.map(children, (child, i) =>
      React.cloneElement(child, {})
    )

    return (
      <>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={(data) => {
            if (pageContext.layout === 'bio') {
              return (
                <>
                  <Normalize />
                  <ThemeProvider theme={gridTheme}>
                    <LinksPageWrap>{childrenWithProps}</LinksPageWrap>
                  </ThemeProvider>
                </>
              )
            } else if (pageContext.layout === 'registration') {
              return (
                <>
                  <Normalize />
                  <ThemeProvider theme={gridTheme}>
                    <RegistrationPageWrap>
                      {childrenWithProps}
                    </RegistrationPageWrap>
                  </ThemeProvider>
                </>
              )
            } else if (pageContext.layout === 'for-sale') {
              return (
                <>
                  <Normalize />
                  <ThemeProvider theme={gridTheme}>
                    <ForSalePageWrapper>{childrenWithProps}</ForSalePageWrapper>
                  </ThemeProvider>
                </>
              )
            } else {
              return (
                <>
                  <Normalize />
                  <ThemeProvider theme={gridTheme}>
                    <Announcement theme={gridTheme} />
                    <Page>
                      <Header
                        siteTitle={data.site.siteMetadata.title}
                        landing={isLandingPage(path)}
                      />
                      <Wrap>{childrenWithProps}</Wrap>
                      <LightboxForm />
                      <Footer
                        landing={isLandingPage(path)}
                        slug={path.split('/')[1]}
                      />
                    </Page>
                  </ThemeProvider>
                </>
              )
            }
          }}
        />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Page = styled.div`
  ${fontRegular()};
  ${responsiveFont()};
  padding: 0 5vw;
  color: ${theme.colors.grey1};
  a {
    text-decoration: none;
  }
  p {
    margin-top: 0px;
  }
  @media (max-width: 1100px) {
    padding: 0 3vw;
  }
  @media (max-width: 800px) {
    padding: 0 4px;
  }
`

const Wrap = styled.main`
  max-width: ${theme.dimensions.maxWidth};
  margin: 0 auto;
  z-index: 0;
  position: relative;
`
